import {useTranslation} from 'gatsby-plugin-react-i18next';
import React from 'react';
import tw, {styled} from 'twin.macro';
import {Button} from '../../Button';

const Wrapper = styled.div<{open: boolean}>`
  ${tw`fixed left-0 top-0 w-full h-full z-30 flex justify-center items-center transition-opacity`}

  opacity: ${props => (props.open ? '1' : '0')};
  visibility: ${props => (props.open ? 'visible' : 'hidden')};
`;

const Overlay = styled.div`
  ${tw`absolute left-0 top-0 w-full h-full`}
  background-color: ${props => props.theme.backgroundOverlay};
`;

const Popup = styled.div`
  ${tw`w-full flex flex-col relative z-30`}
  background-color: ${props => props.theme.colors.defaultBackground};
  box-shadow: 9px 12px 36px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 43px 21px 34px 21px;

  max-width: 90%;
  @media ${props => props.theme.screens.sm} {
    padding: 40px 50px;
    max-width: 440px;
  }

  @media ${props => props.theme.screens.lg} {
    max-width: 500px;
    padding: 50px 60px;
  }

  h3 {
    color: #1f4741;
    margin-bottom: 20px;
    font-weight: bold;
    line-height: 127%;
    font-size: 20px;
  }

  p {
    font-weight: 500;
    line-height: 127%;
    color: #1f4741;
    margin-bottom: 10px;
    font-size: 14px;
  }
  @media ${props => props.theme.screens.md} {
    h3 {
      font-size: 25px;
    }
    p {
      font-size: 16px;
    }
  }
`;

const Heading = styled.div`
  ${tw`text-secondary`}

  font-weight: bold;
  font-size: 20px;
  color: #1f4741;
  line-height: 133%;

  text-align: center;
  letter-spacing: -0.01em;
  margin-bottom: 20px;
`;

const CloseButon = styled(Button)`
  ${tw`text-primary font-medium`}
  color: white;
  height: 50px;
  font-size: 18px;
  line-height: 35px;
  width: 150px;
  margin: 0 auto;
  margin-top: 30px;
`;

type GiftcardTermsPopupProps = {
  open: boolean;
  handleClose: () => void;
};
const GiftcardTermsPopup = ({open, handleClose}: GiftcardTermsPopupProps) => {
  const {t} = useTranslation();

  return (
    <Wrapper open={open}>
      <Overlay onClick={handleClose} />
      <Popup>
        <Heading>{t('gift_terms_title')}</Heading>
        <p>1. {t('card_term_1')}</p>
        <p>2. {t('card_term_2')}</p>
        <p>3. {t('card_term_3')}</p>
        <p>4. {t('card_term_4')}</p>
        <p>5. {t('card_term_5')}</p>
        <CloseButon onClick={handleClose}>OK</CloseButon>
      </Popup>
    </Wrapper>
  );
};

export default GiftcardTermsPopup;
