import React from 'react';
import {styled} from 'twin.macro';
import CardStyleOne from './CardStyleOne';
import CardStyleTwo from './CardStyleTwo';
import CardStyleThree from './CardStyleThree';
import CardStyleFour from './CardStyleFour';

const Wrapper = styled.div`
  width: 100%;

  @media ${props => props.theme.screens.lg} {
    width: 400px;
  }
  @media ${props => props.theme.screens.lg} {
    width: 400px;
  }
  @media (min-width: 1111px) {
    width: 450px;
  }
  @media ${props => props.theme.screens.xl} {
    /* width: 500px; */
  }
`;

const MiniCards = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  overflow-x: scroll;
  width: 100%;

  /* width */
  &::-webkit-scrollbar {
    width: 1px;
    height: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #d7dddc;
    border-radius: 6px;
    margin-left: 10px;
    visibility: hidden;
  }

  /* Show the scrollbar on hover only */
  &:hover {
    &::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
`;

const Cards = styled.div`
  border-radius: 14px;
  @media ${props => props.theme.screens.sm} {
    border-radius: 18px;
  }
  overflow: hidden;
`;

const MiniCard = styled.li<{selected: boolean}>`
  cursor: pointer;
  opacity: ${props => (props.selected ? 1 : 0.22)};
  /* padding-bottom: 10px; */
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 10px;
`;

const MinicardWrapper = styled.div`
  position: relative;
  flex-basis: 22%;
`;
const Bottomshadow = styled.div`
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0px;
  background-color: #1f9638;
`;

type CardStylesProps = {
  amount: number;
  cardStyle: string;
  setCardStyle: (style: string) => void;
  validTill: string;
  voucherNumber: number;
};

const CardStyles = (props: CardStylesProps) => {
  const {amount, cardStyle, setCardStyle, validTill, voucherNumber} = props;
  const items = [
    'one-christmas-tree',
    'two-grandparents',
    'three-gifts',
    'four-flower',
  ];
  const data = {
    amount,
    validTill,
    voucherNumber,
    showDetails: false,
  };
  const cards = {
    [items[0]]: CardStyleOne,
    [items[1]]: CardStyleTwo,
    [items[2]]: CardStyleFour,
    [items[3]]: CardStyleThree,
  };

  return (
    <Wrapper>
      <Cards>
        {cardStyle === items[0] && <CardStyleOne {...data} showDetails />}
        {cardStyle === items[1] && <CardStyleTwo {...data} showDetails />}
        {cardStyle === items[2] && <CardStyleFour {...data} showDetails />}
        {cardStyle === items[3] && <CardStyleThree {...data} showDetails />}
      </Cards>
      <MiniCards>
        {items.map(item => (
          <MinicardWrapper key={item}>
            <MiniCard
              onClick={() => setCardStyle(item)}
              selected={cardStyle === item}
            >
              {cards[item]({...data})}
            </MiniCard>
            {cardStyle === item && <Bottomshadow />}
          </MinicardWrapper>
        ))}
      </MiniCards>
    </Wrapper>
  );
};

export default CardStyles;
