import {useTranslation} from 'gatsby-plugin-react-i18next';
import React from 'react';
import tw, {styled} from 'twin.macro';
import {Button} from '../../Button';

const Wrapper = styled.div<{open: boolean}>`
  ${tw`fixed left-0 top-0 w-full h-full z-30 flex justify-center items-center transition-opacity`}

  opacity: ${props => (props.open ? '1' : '0')};
  visibility: ${props => (props.open ? 'visible' : 'hidden')};
`;

const Overlay = styled.div`
  ${tw`absolute left-0 top-0 w-full h-full`}
  background-color: ${props => props.theme.backgroundOverlay};
`;

const Popup = styled.div`
  ${tw`w-full flex flex-col relative z-30`}
  background-color: ${props => props.theme.colors.defaultBackground};
  box-shadow: 9px 12px 36px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 43px 30px 34px 30px;

  max-width: 86%;
  @media ${props => props.theme.screens.sm} {
    max-width: 400px;
  }
  @media ${props => props.theme.screens.md} {
    max-width: 560px;
  }

  h3 {
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #1f4741;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #1f4741;
  }
`;

const Heading = styled.div`
  ${tw`text-secondary`}

  font-weight: bold;
  font-weight: bold;
  font-size: 25px;
  line-height: 127%;
  text-align: center;

  color: #1f4741;

  text-align: center;
  margin-bottom: 20px;
`;

const CloseButon = styled(Button)`
  ${tw`bg-primary font-medium`}
  color: white;
  padding: 0;
  font-size: 18px;
  line-height: 35px;
  margin: 0 auto;
  margin-top: 30px;
  padding-top: 7px;
  padding-bottom: 7px;
  height: min-content;
  width: 140px;
`;

const GiftCardSuccess = ({open, handleClose}) => {
  const {t} = useTranslation();

  return (
    <Wrapper open={open}>
      <Overlay onClick={handleClose} />
      <Popup>
        <Heading>{t('success_thank_you')}</Heading>
        <p>{t('success_manager_contact_you')}</p>
        <CloseButon onClick={handleClose}>OK</CloseButon>
      </Popup>
    </Wrapper>
  );
};

export default GiftCardSuccess;
