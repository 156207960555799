import {StaticImage} from 'gatsby-plugin-image';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {styled} from 'twin.macro';
import {CardProps} from './CardStyleOne';

const CardWrapper = styled.div`
  position: relative;
`;

const Details = styled.div`
  position: absolute;
  bottom: 10%;
  width: 45%;
  margin: 0 auto;
  right: 5%;
  @media ${props => props.theme.screens.sm} {
    bottom: 9%;
  }
  /* @media ${props => props.theme.screens.md} {
    bottom: 8%;
  } */
  @media ${props => props.theme.screens.lg} {
    bottom: 8%;
  }
  @media (min-width: 1111px) {
    bottom: 9%;
  }
  @media ${props => props.theme.screens.xl} {
    bottom: 9%;
  }
`;

const Field = styled.div`
  background-color: white;
  margin-bottom: 5px;
  font-size: 8px;
  padding: 1px 5px;
  letter-spacing: 0.075rem;
  text-transform: uppercase;
  color: #0e4841;
  :first-of-type {
    margin-top: 0px;
  }
  @media ${props => props.theme.screens.sm} {
    font-size: 9px;
    padding: 2px 7px;
    margin-bottom: 7px;
  }
  /* @media ${props => props.theme.screens.md} {
    font-size: 10px;
    padding: 3px 7px;
  } */

  @media ${props => props.theme.screens.xl} {
    font-size: 10px;
    padding: 3px 7px;
  }

  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
`;

export default function CardStyleThree({
  showDetails,
  amount,
  validTill,
  voucherNumber,
}: CardProps) {
  const {t} = useTranslation();
  return (
    <CardWrapper>
      <StaticImage
        src="../../../../assets/card-style3.png"
        layout="constrained"
        alt="card-style-one"
        placeholder="blurred"
      />
      {showDetails && (
        <Details>
          <Field>
            {t('card_amount')}: {amount}
          </Field>
          <Field>
            {t('voucher_number')}: {voucherNumber}
          </Field>
          <Field>
            {t('voucher_validity')}: {validTill}
          </Field>
        </Details>
      )}
    </CardWrapper>
  );
}
